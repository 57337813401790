import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import "./i18n";

import { store } from "./app/store";

import * as serviceWorker from "./serviceWorker";

import App from "./App";

import "./index.css";

import ReactGA from 'react-ga';
import { ThemeProvider } from "./context/ThemeContext";
import { DataSheetProvider } from "./context/ContactSpecific";


ReactGA.initialize('G-RGKT9H0M5N');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <ThemeProvider> */}
      <DataSheetProvider>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <App />
          </Suspense>
        </BrowserRouter>
      </DataSheetProvider> 
      {/* </ThemeProvider>       */}
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// Ativar o rastreamento do Google Analytics para cada mudança de página
ReactGA.pageview(window.location.pathname + window.location.search);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
