import { Container, Row, Col } from "react-bootstrap";

import { FamilyModel } from "../../models/FamilyModel";
import { TypeModel } from "../../models/TypeModel";

import MainTitle from "../../components/MainTitle/MainTitle";
import FamilyDescription from "../../components/FamilyDescription/FamilyDescription";
import BreadcrumbPath from "../../components/BreadcrumbPath/BreadcrumbPath";
import CategoryItem from "../../components/CategoryItem/CategoryItem";
import { Image, Card } from "react-bootstrap";
import "./Family.css";
import { useCallback, useEffect, useState } from "react";
import MediaViewer from "../MediaViewer/MediaViewer";
import DataSheetService from "../../services/api/DataSheetService";
import DataSheetEntitie from "../../models/DataSheetEntitie";
import Util from "../../utils/Util";
import i18n from "../../i18n";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NameSpace } from "../../data/AppLanguage";
import { Box, Grid, Typography } from "@mui/material";
import CustomerDataSheetService from "../../services/api/CustomerDataSheet";
import CustomerDataSheetByFamilyResponse from "../../models/CustomerDataSheetByFamilyResponse";

interface ReviewOptions {
  text?: string;
  textField?: boolean;
}

interface StyleOptions {
  cardClassName?: string;
  nameClassName?: string;
  borderBottom?: boolean;
  overlay?: boolean;
  scaleImage?: boolean;
  background?: boolean;
  fontSize?: number;
}

interface Props {
  family?: FamilyModel;
  downloadDocument: (item: TypeModel) => void;
  askInformation: () => void;
  className?: string;
}

interface Props {
  category?: CategoryModel;
  primaryMedia?: MediaModel;
  secondaryMedia?: MediaModel;
  energyEfficiencyMedia?: MediaModel;
  name: string;
  review?: ReviewOptions;
  path?: string;
  style?: StyleOptions;
  body?: JSX.Element;
  imageViewer?: () => void;
}


const Family = (props: Props) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [visible, setVisible] = useState(false);
  const [customerDataSheets, setCustomerDataSheets] = useState<CustomerDataSheetByFamilyResponse[]>([]);
  const [familyId, setFamilyId] = useState<any>();
  const { t, i18n } = useTranslation([NameSpace.COMMON, NameSpace.ROUTES]);
  const language = Util.splitLanguage(i18n.language, 0);
  const [isCommercial , setIsCommercial] = useState<boolean>();
  const commercial = localStorage.getItem("isCommercial");
  const productFinalPath = t("productFinalItem", {
    ns: NameSpace.ROUTES,
  });
  const numItems = customerDataSheets.length;
  const justify = numItems <= 1 ? "flex-start" : "center";
  
  const history = useHistory();

  const openImageViewer = useCallback((index) => {
    setVisible(true);
    setCurrentImage(index);
  }, []);
  
  const closeImageViewer = () => {
    setVisible(false);
    setCurrentImage(0);
  };

  var images: { src: string }[] = [];

    useEffect(() => {

      setFamilyId(props.family?.id)
    },)

    useEffect(() =>{   
      if(props.family?.id) {
        CustomerDataSheetService.getCustomerDataSheetsByFamily(familyId, language.toUpperCase()).then((res) =>{
          setCustomerDataSheets(res.data);
        })  
      }           
      },[familyId])
  //------------------------------------------------------------------------------------------------------------------
  
 
  const ancestors = props.category?.ancestors?.map((item) => {
    return item.path;
  });

  const buildUrl = `/${language}${
    ancestors && ancestors.length > 0 ? `/${ancestors.join("/")}/` : "/"
  }${props.category?.current.path}/`;

  const image = (url : string) => (
    <div className="position-relative">
      {props.secondaryMedia ? (
        <Card.Img
          src={url}
          className="category-item-secondary-media"
        />
      ) : null}
      <Card.Img
        src={url}
        className={`category-item-primary-media overLay ${
          props.style?.scaleImage ? "category-item-primary-media-scale" : ""
        }`}
        role={`${props.imageViewer ? "button" : ""}`}
        onClick={() => {
          if (props.imageViewer) props.imageViewer();
        }}
      />
      {props.energyEfficiencyMedia ? (
        <Image
          src={props.energyEfficiencyMedia.path}
          className="category-item-energy-efficiency-media"
          fluid
        />
      ) : null}
    </div>
  );

  const overlayStyle = (element: JSX.Element) => {
    return props.style?.overlay ? (
      <div className="category-item-overlay">
        {element}
        {/* Commented just in case it would be needed again */}
        {/* <div className="category-item-overlay-wrapper">
          <MdAdd className="category-item-overlay-wrapper-icon" />
        </div> */}
      </div>
    ) : (
      element
    );
  };

  const imageStyle = (url: string) => {
    return props.style?.background
      ? overlayStyle(<div className="category-item-background">{image(url)}</div>)
      : overlayStyle(image(url));
  };

  return (
    <Box  className={`family ${props.className} p-0`}>
          {/* <Box>
            <MainTitle media={props.family?.media} height="58vh" />
          </Box> */}
         
          <Box justifyContent={'center'} mt={10}>        
            <BreadcrumbPath
                  ancestors={props.family?.ancestors}
                  current={props.family?.name}
                />                         
          </Box>

          <article>
            <Grid  container justifyContent={justify}
              maxWidth={window.innerWidth > 1300 ? "xl" : "none"}
              marginLeft={window.innerWidth > 1300 ? 20 : "none"}
              // spacing={5} 
              sx={{ paddingLeft: numItems <=1 && window.innerWidth > 1300 ? 20 : 5 , paddingRight: 4}}
              // columns={{ xl: 12}}
              >
              {customerDataSheets?.sort((a , b) => Number(a.position) - Number(b.position)).map((item, index) => {
                images.push({ src: item.media_Main_url });
              
                return (
                  <Grid item
                    md={3}
                    xl={3}
                    className="p-0"
                    key={index}                    
                    sx={{ 
                      transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      borderRadius: "4px",
                      boxShadow: "3px -3px 1px -3px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
                      marginX: "auto",
                      mb: 4,
                      margin: "0px 15px 30px 15px",
                     }}                   
                  >
                    <Link to={`/${language}/${productFinalPath}/${item.id}`}>
                      <Card className={`category-item w-100 ${props.style?.cardClassName}`}>
                        {props.path ? (
                          <Link to={`${props.category ? buildUrl : ""}${props.path}`}>
                            {imageStyle(item.media_Main_url)}
                          </Link>
                        ) : (
                          imageStyle(item.media_Main_url)
                        )}
                        <Card.Body
                          className={`category-item-body pl-0 pr-0 ${
                            props.style?.borderBottom ? "category-item-border-bottom mt-4" : ""
                          }`}
                        >
                          <Card.Title>
                            <Typography component={'h2'} variant="h6"
                            fontSize={15}
                            fontWeight={400}
                            fontFamily={'Rubik'}
                            sx={{ color: "#2b5b7a", letterSpacing: '1px'}}
                            >
                              {item.customerDataSheetTranslations?.subtitle} 
                            </Typography>
                            {
                              commercial === String(false) ? ( 
                              <Typography component={'h2'} variant="h6"
                              fontSize={15}
                              fontWeight={400}
                              fontFamily={'Rubik'}
                              sx={{ color: "#2b5b7a", letterSpacing: '1px'}}
                              >
                              {item.customerDataSheetTranslations?.title} 
                              </Typography>
                              ) : null
                            }
                            
                          </Card.Title>
                          {props.review && props.review.text ? (
                            <div
                              className={`category-item-body-text ${
                                !props.review.textField ? "category-item-body-text-clamping" : ""
                              }`}
                            >
                              <Card.Text as={Markup} content={props.review.text} />
                            </div>
                          ) : null}
                          {props.body}
                        </Card.Body>
                      </Card>
                    </Link>
                  </Grid>
                );
              })}
            </Grid>
          </article>
    </Box>
  );
};

export default Family;
