import { useEffect, useRef, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import { GalleryModel } from "../../models/GalleryModel";
import { GammaModel } from "../../models/GammaModel";

import {
  NameSpace,
  newsEvents as newsEventsNameSpace,
} from "../../data/AppLanguage";
import { mediumWidth } from "../../data/BreakpointsWidth";

import Util from "../../utils/Util";

import GammaService from "../../services/api/Gamma";

import Slider from "../../components/SliderV2/Slider";
import ContactPhoneForm from "../../components/ContactPhoneForm/ContactPhoneForm";
import Gamma from "../../components/Gamma/Gamma";
import ProductCarousel from "../../components/ProductCarousel/ProductCarousel";
import Gallery from "../../components/Gallery/Gallery";
import ContactGenericForm from "../../components/ContactGenericForm/ContactGenericForm";
import MostWanted from '../../components/MostWanted/MostWanted';

const Home = () => {
  const [gammas, setGammas] = useState<GammaModel[]>([]);
  const [newsEvents, setNewsEvents] = useState<GalleryModel[]>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const { t, i18n } = useTranslation([
    NameSpace.CONTACTFORMS,
    NameSpace.PAGES,
    NameSpace.ROUTES,
  ]);
  const language = Util.splitLanguage(i18n.language, 0);

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {     
      GammaService.getGammas(language).then((res) => {
        setGammas(res.data);
        document.title = Util.setCategoryDocumentTitle(
          res.data.map((g) => g.name)
        );
      });

      window.addEventListener("resize", handleWindowSizeChange);
    }

    return () => {
      mounted = false;

      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [language]);

  return (
    <>
      {<Slider />}
      <ContactPhoneForm />
      <Gamma gammas={gammas} />
      <MostWanted />
      {/* <ProductCarousel /> */}
      {newsEvents.length ? (
        <Gallery
          header={
            <Trans i18nKey={`${newsEventsNameSpace}v1`} ns={NameSpace.PAGES}>
              Notícias & <br /> Eventos
            </Trans>
          }
          path={t("newsEvents", { ns: NameSpace.ROUTES })}
          galleryItems={newsEvents}
          className={windowWidth < mediumWidth ? "mt-3" : "mt-11"}
          border={windowWidth < mediumWidth ? false : true}
          galleryItemsColSpec={{ xs: 12, md: 9 }}
        />
      ) : null}
      {/* {<ContactGenericForm className="mt-10" />} */}
      {<ContactGenericForm />}
    </>
  );
};

export default Home;
